@import "../../utils";

.etv-meeting-attend-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;


  .etv-icon {
    max-width: 300px;
    max-height: 150px;
    margin-left: 20%;
    display: none;

    @include breakpoint(s) {
      display: flex;
      max-width: 500px;
      max-height: 200px;
      margin-left: 25%;
    }
  }

  .etv-meeting-attend-information {
    max-width: 200px;
    @include spacing(padding, 0, xs, 0);
    display: none;


    @include breakpoint(s) {
      display: block;
      max-width: 600px;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .etv-meeting-attend-information {
    &.mobile {
      display: block;
    }
  }

  .text-input-field  {
    display: flex;
    flex-direction: column;
    align-items: start;

    input {
      font-size: 28px;
      font-family: monospace;
      width: 156px;
      height: 50px;
      letter-spacing: 18px;
      padding: 0;
      padding-left: 16px;
    }
    /* Für Chrome, Safari, Edge */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Für Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .form-fields-wrapper {
    width: 300px;
    position: relative;
    .etv-pin-wrapper {
      display: flex;
      align-items: center;
      @include spacing(margin, xs, 0);

      span {
        @include spacing(margin-right, xs);
      }
    }

    .etv-pin-input {
      position: relative}

    .etv-pin-input:before {
      position: absolute;
      content: "";
      left: 0;
      right: 16px;
      height: 10px;
      bottom: 10px;
      background: linear-gradient(90deg,
              transparent 5%, transparent 10%, /* Abstand vor dem ersten Strich */
              dimgrey 10%, dimgrey 25%, /* Erster Strich */
              transparent 15%, transparent 35%, /* Abstand zum zweiten Strich */
              dimgrey 35%, dimgrey 50%, /* Zweiter Strich */
              transparent 40%, transparent 60%, /* Abstand zum dritten Strich */
              dimgrey 60%, dimgrey 75%, /* Dritter Strich */
              transparent 65%, transparent 85%, /* Abstand zum vierten Strich */
              dimgrey 85%, dimgrey 100%, /* Vierter Strich */
              transparent 90%, transparent 100%)
      0 90% / 100% 2px no-repeat; /* Platzierung des Hintergrunds */
      opacity: 0.2;
    }

    .error-text {
      @include var(color, form-field-error-color)
    }
  }
}
