@import "../../../utils";

.radio-button-wrapper {
  display: flex;
  align-items: center;
  @include spacing(margin-bottom, xs);

  label input[type="radio"] {
    display: none;
  }

  label input[type="radio"] ~ span {
    position: relative;
    display: inline-block;
    @include spacing(padding, 3, xs, 3, xl);
  }

  label input[type="radio"] ~ span:before {
    content: "";
    position: absolute;
    display: block;
    width: 23px;
    height: 23px;
    background: #fff;
    margin-right: 5px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    @include var(background-color, secondary-background-color);
    @include var(border-color, diver-color);
  }

  label input[type="radio"]:checked ~ span:before {
    @include var(border-color, primary-color);
    border-style: solid;
    border-width: 9px;
  }



}
