@import "../../utils";

.navigation-list {
  display: flex;
  flex-direction: row;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-around;

  @include breakpoint(m) {
    display: block;
    flex: 1;
  }

  &.managementsList{
    display: none;
    @include breakpoint(m) {
      display: initial;
    }

    .navigation-list-item-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  > .navigation-list {
    display: none;
    @include breakpoint(m) {
      display: flex;
      flex-direction: column;
    }

    &.isCollapsed {
      display: none;
    }
  }
  > .navigation-list > .navigation-list-item > .navigation-list-item-link {
      @include spacing(padding-left, xxl);
  }

  &-title {
    @include var(color, icon-grey);
    @include spacing(padding, 0, 0, 0, m);
    display: none;
    @include breakpoint(m) {
      display: initial;
    }
  }

  &-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-footer {
    display: none;
    @include breakpoint(m) {
      display: block;
    }
    @include spacing(padding, s);
    @include spacing(font-size, 12);
    white-space: nowrap;

    &-links {
      display: flex;
      @include spacing(padding-bottom, xxs);
      a {
        @include spacing(margin-right, xxs);
      }
    }
    a {
      @include var(color, secondary-color);
      text-decoration: none;
    }

    &-copyright {
      a {
        @include var(color, disable-color);
      }
    }
  }

  &-item {

    &-link {
      @include var(color, secondary-color);
      @include spacing(padding, xxs);
      display: flex;
      text-decoration: none;
      justify-content: center;
      flex: 1;
      white-space: nowrap;

      @include breakpoint(m) {
        @include spacing(padding, xxs, s);
        align-items: center;
        justify-content: inherit;
      }
      &.active {
        .navigation-list-item-icon-path {
          @include var(fill, navigation-color-active);
        }
        .navigation-list-item-icon-path[name="fluks"] {
          fill: none;
          stroke-width: 2;
          @include var(stroke,  navigation-color-active)
        }

        @include breakpoint(m) {
          @include var(background-color, navigation-background-active);
          @include boxShadow(inset, 2px, 0, 0, navigation-color-active);
        }
      }
    }
    &-title {
      display: none;
      @include breakpoint(m) {
        display: inherit;
      }
    }


    &:hover {
      .navigation-list-item-icon-path {
        @include var(fill, navigation-color-active);
      }
      .navigation-list-item-icon-path[name="fluks"] {
        fill: none;
        @include var(stroke, navigation-color-active)
      }

      .navigation-list-item-circle {
        @include var(background-color, navigation-color-active);
      }
      @include breakpoint(m) {
        @include var(color, navigation-color-active);
        @include boxShadow(inset, 2px, 0, 0, navigation-color-active);
      }
    }

    &-icon-container {
      display: flex;
      align-items: center;
      width: 70px;
      height: 70px;
      padding: 2px;

      @include spacing(border-radius, 4);
      @include elevation;
      @include spacing(margin, xxs, s, xs, xxs);
      @include var(background-color, secondary-background-color);
    }

    &-icon {
      @include spacing(margin-right, 4);

      &-path {
        @include var(fill, navigation-icon-color)
      }

      &-path[name="fluks"] {
        fill: none;
        @include var(stroke, navigation-icon-color)
      }
    }

    &-circle {
      border-radius: 50%;
      display: block;
      @include spacing(margin-right, xxs);
      @include spacing(width, xxs);
      @include spacing(height, xxs);
      @include var(background-color, navigation-icon-color);

    }

    &-container {
      display: flex;
      flex-direction: column;

      span:last-child {
        @include var(color, hint-color);
        @include spacing(margin-top, xxs);
      }
    }
  }
}
