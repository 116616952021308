@import "../../../utils";

.agenda-item-proposition-voting-control {
  &-actions {

    button {
      @include spacing(margin-bottom, xs);

      @include breakpoint(s) {
        @include spacing(margin-bottom, 0);
      }
    }
  }

  &-resolution {
    align-self: flex-end;
  }

  .resolution-green {
    @include var(background-color, button-background-green);
    @include var(color,  secondary-background-color);
  }

  .resolution-points {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include spacing(margin-left, 0);

    @include breakpoint(s) {
      @include spacing(margin-left, xs);
    }

    span {
      display: inline-block;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
