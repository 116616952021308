@import "../../../utils";

.meeting-invitation {
  @include spacing(padding, s);

  &-headline {
    font-weight: 600;
    @include spacing(font-size, 18);
    @include spacing(margin-bottom, l);
  }

  &-content {
    @include spacing(font-size, xs);
    @include spacing(margin-bottom, m);
  }

  &-actions {
    display: flex;
    flex-direction: column;

    .button {
      @include spacing(margin-right, m);
      @include spacing(margin-top, xs);
      width: 100%;
      @include breakpoint(s) {
        @include spacing(margin-top, 0);
        width: inherit;
      }
    }

    @include breakpoint(s) {
      flex-direction: row;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    @include boxShadow(0, 1px, 0, 0);
    @include spacing(padding-bottom, m);

    &-title {
      flex-basis: 120px;
      @include spacing(padding, xxs, 0);
      @include spacing(font-size, xs);
    }

    &-content {
      @include spacing(padding, xxs, 0);
      flex-basis: calc(100% - 120px);
      margin: 0;
      @include spacing(font-size, xs);
      font-weight: 600;

      &-text {
        display: flex;
        flex-direction: column;
        overflow-wrap: anywhere;

        @include breakpoint(s) {
          flex-direction: row;
        }

        span {
          font-weight: 600;
        }
      }

      &-button {
        @include spacing(margin-left, 0);

        @include breakpoint(s) {
          @include spacing(margin-left, xs);
        }
      }


        .wrapper-class {
          min-height: 200px;
          border-width: 4px;
          border-style: solid;
          @include var(border-color, button-border-color-low);
          @include var(background-color,  secondary-background-color);
          @include spacing(margin-top, xs);
          @include spacing(padding, xs);
          font-weight: normal;
        }

        .editor-buttons div{
          right: 1%;
        }

        .editor-class {
          min-height: 200px;
          border-width: 1px;
          border-style: solid;
          @include var(border-color, button-border-color-low);
          @include spacing(padding, xs);
          @include var(background-color, secondary-background-color);
        }

        .toolbar-class {
          border-width: 1px;
          border-style: solid;
          @include var(border-color, button-border-color-low);
        }

        .editor-buttons-wrapper {
          display: flex;
          align-items: center;

          h3 {@include spacing(margin-right, s);
          }
          button {
            @include spacing(margin-right, xxs);
          }
        }

    }
    &-content.link {
      font-weight: normal;
      overflow: auto;
    }

    &-content-small {
      @include spacing(padding, xxs, 0);
      flex-basis: calc(100% - 120px);
      margin: 0;
    }
  }

  .dialog-delegate {
    .dialog-description{
      overflow: auto;
    }
  }

  .dialog-delegate.invitation {
    max-width: 350px;

    .dialog-actions {justify-content: space-between}

    @include breakpoint(s) {
      max-width: 100%;
      flex-basis: calc(80% - 80px);
    }

    .dialog-description{
      overflow: auto;
      max-height: 320px;

      @include breakpoint(s) {
        max-height: 520px;
        flex-basis: calc(80% - 80px);
      }
    }
  }

  .dialog-close {
    .dialog-actions {justify-content: space-between}
  }

}
