@import "../../../utils";

.invitation-link-editor-buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include breakpoint(s) {
    justify-content: flex-end;
    flex-direction: row;
  }

  .info-text-wrapper {
    @include spacing(margin, xs, xl, 0, 0);
  }

  button {
    @include spacing(margin-top, s);

    &:last-child {
      @include spacing(margin-left, xs);
    }
  }
}