@import "../../utils";

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  @include spacing(margin-top, s);

  input {
    width: 22px;
    height: 22px;
    @include var(accent-color, primary-color);
    cursor: pointer;
  }

  &-label {
    display: flex;
    align-items: center;
  }

  input[disabled]:checked {
    @include var(background-color, primary-color);
  }

  span {
    display: flex;
    text-align: start;
    @include spacing(margin-left, s);
  }

}