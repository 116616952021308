@import "../../utils";

.checkbox-field {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  @include spacing(margin-top, s);

  input {
    width: 22px;
    height: 22px;
  }

  &-label {
    display: flex;
    align-items: center;
  }

  .checkbox-field-label span {
    display: flex;
    text-align: start;
  }
}