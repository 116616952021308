@import "../../../utils";

.etv {
  width: 100vw;
  padding-bottom: 64px;

  @include breakpoint(m) {
    flex: 1;
    width: 100%;
    @include spacing(padding, m, xl);
  }

  .etv-meeting-control {
    display: flex;
    flex-direction: row;
    align-items: center;

    .actions-warning-info {
      cursor: pointer;
      margin-right: 0;
      @include breakpoint(s) {
        @include spacing(margin-right, s);
      }

      path {
        @include var(fill, message-state-open-color);
      }
    }

    button {
      margin-right: 0;
      @include breakpoint(s) {
        @include spacing(margin-right, xs);
      }
    }
  }

  .tab-bar + .etv-agenda {
    @include spacing(margin, 0, 0, 64);
  }

  .tab-bar {
    @include spacing(margin, 104, 0, 0);
    @include breakpoint(m) {
      margin: 0;
    }
  }

  .meeting-beenden-dialog-info {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    text-align: left;

    span {
      @include spacing(margin-bottom, s);
    }
  }
}