@import "../../utils";

.dialog {
  margin: auto;
  @include boxShadow(0, 4px, 14px, 0, dialog-shadow);
  @include var(background-color, secondary-background-color);
  @include var(color, secondary-color);
  @include spacing(border-radius, xs);
  display: flex;
  flex-direction: column;
  text-align: center;

  @include breakpoint(l) {
    max-width: 75vw;
  }

  &.dialog-alert {
    .dialog-actions {
      justify-content: flex-end;
    }
  }
  &-title {
    @include spacing(margin, xs, xs, xs, xs);
    @include breakpoint(s) {
      @include spacing(padding, xs);
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .close-button-wrapper{
      @include spacing(margin-right, -16);
    }
  }

  &-description {
    @include spacing(padding, 0, l, m );
    display: block;
    overflow: auto;
    max-height: 25vh;

    @include breakpoint(xs) {
      max-height: 65vh;
    }

    .terms-of-use-content{
      text-align: justify;
      width: 80vw;
      @include breakpoint(m) {
        max-height: 100vh;
        text-align: justify;
        &:not(.fullscreen) {
          max-width: calc(110% - 280px);
        }
      }

      & > * {
        @include spacing(margin, xs, 0);
      }
    }

    &-content {
      cursor: pointer;
      text-decoration: underline;
      @include spacing(padding, s, 0, s, 0);
    }

    &-list {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  &-spinner {
    @include spacing(padding, xs);
  }
  &-actions {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    border-top-style: solid;
    border-top-width: 1px;

    @include spacing(padding, xs);
    @include var(border-top-color, border-color);
    @include breakpoint(s) {
      flex-direction: row;
      justify-content: space-between;
    }

    &.cancel-button-hidden {
      @include breakpoint(s) {
        flex-direction: row;
        justify-content: center;
      }
    }

    .pages {
      @include spacing(margin-bottom, xxs);

      @include breakpoint(s) {
        @include spacing(margin-bottom, 0);
      }
    }

    button {
      @include spacing(margin-bottom, xxs);
      min-width: 75px;
    }
  }

  &-background-layer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    @include var(background-color, elevation-color-high-opacity);
  }

  .close-button-wrapper {
    display: flex;
    justify-content: flex-end;
    .dialog-button-close {
      @include spacing(padding, 0, xxs);
      @include spacing(margin, xxs, xxs, 0,0);
      max-height: 35px;
      svg {
        margin: 0;
      }
    }

  }

  .button {
    margin-right: 0;
  }
}
