@import "../../../utils";

.object-owner-wrapper {

  .object-owner-back {
    display: none;
    @include spacing(margin-left, l);
    @include spacing(margin-bottom, s);
    @include breakpoint(m) {
      display: flex;
      align-items: center;
      cursor: pointer;
      max-width: max-content;
      @include var(color, hint-color);
      text-decoration: none;
      svg {
        transform: rotate(90deg);
        path {
          @include var(fill, hint-color);
        }
      }
    }
  }

  .object-owner-container {
    display: flex;
    align-items: start;
    flex-direction: column;

    @include breakpoint(s) {
      width: 100%;
      flex-direction: initial;
    }

    .object-owner-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include spacing(margin-right, xxs);

      h3 {
        @include spacing(margin-bottom, s);
        @include spacing(margin-left, xs);
      }

      &-list-body {
        width: 100%;
        overflow: auto;
        height: calc(100vh - 184px);
        @include breakpoint(s) {
          height: calc(100vh - 160px);
          margin: inherit;
        }

      }
      .sort-header {
        width: 100%;
        @include boxShadow(0, 1px, 0, 0);
        @include spacing(padding-bottom, xxs);
      }
    }
  }
}