@import "../../utils";

.invitation-dialog-wrapper {
  .invitation-dialog-question-wrapper {
    @include spacing(margin-bottom, s);

    .invitation-dialog-question {
      display: flex;
      justify-content: flex-start;
      @include spacing(margin, xxs, 0);
      @include var(color, hint-color);
    }
  }
}
